<template>
  <main v-if="apiData && includedTeasers">
    <WidthWrapper>
      <div class="max-w-780 mx-auto pt-20 md:pt-40 px-10 pb-40">
        <PageTitle :title="apiData.data.attributes.name" page-type="category" />
        <section class="grid grid-cols-6 gap-x-20 md:gap-y-60">
          <TeaserOne lazy-img="eager" class="col-span-6 md:col-span-2" :teaser="includedTeasers[0]" />
          <template v-for="(teaser, index) in includedTeasers.slice(1, 3)">
            <TeaserAside3 v-if="teaser" :key="teaser.id + index" lazy-img="eager" class="col-span-6 md:col-span-2" :teaser="teaser" />
          </template>
          <template v-for="(teaser, index) in includedTeasers.slice(3, 6)">
            <TeaserAside3 v-if="teaser" :key="teaser.id + index" lazy-img="eager" class="col-span-6 md:col-span-2" :teaser="teaser" />
          </template>
          <AdWrapper class="mb-40 col-span-6" :ad="{ slotId: 'mob_responsive_2' }" grey-background />
          <AdWrapper class="mb-40 col-span-6" :ad="{ slotId: 'dsk_rec_ad_3' }" grey-background />
          <template v-for="(teaser, index) in includedTeasers.slice(6, 12)">
            <TeaserAside3 v-if="teaser" :key="teaser.id + index" class="col-span-6 md:col-span-2" :teaser="teaser" />
          </template>
          <AdWrapper class="mb-20 col-span-6" :ad="{ slotId: 'mob_responsive_4' }" grey-background />
          <AdWrapper class="mb-20 col-span-6" :ad="{ slotId: 'dsk_rec_ad_4' }" grey-background />
          <template v-for="(teaser, index) in includedTeasers.slice(12, 18)">
            <TeaserAside3 v-if="teaser" :key="teaser.id + index" class="col-span-6 md:col-span-2" :teaser="teaser" />
          </template>
        </section>
        <LoadMore :initial-count="18" :api-url="apiData.links.related" :total-count="apiData.data?.attributes?.articleCount" :ads="false" />
      </div>
    </WidthWrapper>
    <LazyGlobals />
  </main>
</template>

<script setup lang="ts">
const apiData = inject('apiData') as Aller.Category;
const { includedTeasers } = useAllerRelationships(apiData);
</script>
